<template>
    <div>
        <div class="dataFilterWrap">
            <el-row :gutter="3">
                <el-col :span="8">
                    <label>时间范围</label>
                    <el-date-picker v-model="starttime" type="date" placeholder="选择开始日期" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-date-picker v-model="endtime" type="date" placeholder="选择结束日期" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
                <el-col :span="6">
                    <label>统计方式</label>
                    <el-select v-model="method">
                        <el-option label="按出团时间统计" :value="0"></el-option>
                        <el-option label="按提交时间统计" :value="1"></el-option>                         
                    </el-select>
                </el-col>
                <el-col :span="4"><el-button type="primary" round plain @click="pullList">确定</el-button></el-col>
            </el-row>

        </div>
        <el-table :data="orders" stripe fit tooltip-effect="dark" class="orderTable" :default-sort="{prop: 'TravelAgency_TotalCount', order: 'descending'}">

            <el-table-column label="旅行社" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.TravelAgency_Name }}</template>
            </el-table-column>
            <el-table-column label="人数" sortable prop="TravelAgency_TotalCount">
                <!-- <template slot-scope="scope">{{ scope.row.TravelAgency_TotalCount }}</template> -->
            </el-table-column>
            <el-table-column label="金额">
                <template slot-scope="scope">{{ Number(scope.row.TravelAgency_TotalMoney).toFixed(2) }}</template>
            </el-table-column>

        </el-table>

    </div>
</template>

<script>
export default {
    data() {
        return {
            orders: [],
            starttime: "",
            endtime: "",
            method: 0
        }
    },
    mounted() {
        this.pullList()
    },
    methods: {
        pullList(num) {
            this.ipost(
                "/AdminTravel/CashTravel.ashx?type=staticstravelagencyonlydelay", {
                pageindex: this.pageindex,
                pagesize: this.pagesize,
                starttime: this.starttime,
                endtime: this.endtime,
                method: this.method

            },
                (res) => {
                    this.orders = res.list
                }
            );
        }
    }
}
</script>

<style scoped>
.img-show {
    padding: 12px;
    text-align: center;
}

.downBtn a {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 6px;
    line-height: 40px;
    text-align: center;
    padding: 0 12px;
}

.del-mark {
    text-decoration: line-through;
    position: relative;
}

.del-mark::after {
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    border-top: 1px solid #333;
}

.downBtn a:hover {
    border-color: #1D82FE;
    color: #1D82FE;
}

.btnCol .el-button {
    margin-right: 8px;
}

.policyPerson .el-col {

    margin: 10px 0;
}

.policyPerson .el-button {
    margin-left: 8px;
}

.bottom-btn-wrp {
    text-align: center;
    padding: 20px 0 0;
}

.addPersonwrp label {
    line-height: 2.3;
}

.stateText {
    display: inline-block;
    font-size: 27px;
    color: tomato;
    font-weight: bold;
    padding: 10px;
    border: 3px solid red;
    text-align: center;
    border-radius: 4px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    position: absolute;
    top: 130px;
    left: 70%;
    z-index: 10;
    transform: translate(-50%, -50%) rotateZ(-35deg);
}

.policyPerson {
    margin-top: 27px;
    padding-top: 27px;
    border-top: 2px dashed #efefef;
}

.policyDetail label,
.policyPerson label {
    color: #999;
    margin-right: 12px;
    line-height: 2.1;

}

.policyPerson span,
.policyDetail span {
    font-weight: bold;
    font-size: 15px;

}

.dataFilterWrap {
    padding: 10px 20px;
}

.dataFilterWrap .el-row {
    margin: 20px 12px;
}

.dataFilterWrap label {
    display: inline-block;
    width: 70px;
    margin-right: 12px;
}

.dataFilterWrap .el-select,
.dataFilterWrap .el-input {
    width: 220px;
}
</style>
